<template>
  <Body class="relative bg-brand-greyblack-300 font-Abu overflow-x-hidden">
    <Teleport to="body">
      <Transition name="fade-fast" mode="out-in">
        <div
          v-if="modal"
          :class="modalClass"
          class="modal__overlay fixed transition-all duration-75 top-0 start-0 w-full h-full flex justify-center bg-brand-greyblack-300/10 z-40 backdrop-blur-sm gap-2 md:gap-4 lg:gap-6 px-4 md:px-10 lg:px-28 py-4 md:pb-10 pt-[calc(2.5rem_+_52px)] md:pt-[calc(2.5rem_+_52px)]"
          @click.stop="onOverlayClick"
        >
          <slot name="container">
            <!-- bg-brand-greyblack-300 -->
            <div
              :class="containerClass"
              ref="container"
              class="modal__container bg-brand-greyblack-300 relative z-50 w-full h-full max-w-[1280px] max-h-[1080px] rounded-2xl backdrop-blur-xl shadow-xl bg-center bg-no-repeat bg-cover overflow-y-auto"
              @click.stop=""
            >
              <slot name="close">
                <ButtonsIcon
                  is-dark
                  icon-name="IconTimesWhite"
                  class="absolute top-5 end-10 fill-white"
                  @click="onOverlayClick"
                ></ButtonsIcon>
              </slot>

              <slot></slot>
            </div>
          </slot>
        </div>
      </Transition>
    </Teleport>
  </Body>
</template>

<script setup>
import { PLAYERS } from "@/constants";

const props = defineProps({
  modal: {
    type: Boolean,
    default: () => false,
  },
  modalClass: String,
  containerClass: String,
});

const emit = defineEmits("onOverlayClick");
const onOverlayClick = () => emit("onOverlayClick");
watch(
  () => props.modal,
  (newValue) => {
    if (newValue) {
      useAllPlayers().pause(PLAYERS.HERO_PLAYER);
    } else {
      useAllPlayers().resume(PLAYERS.HERO_PLAYER);
    }
  }
);
</script>

<style lang="scss" scoped></style>
